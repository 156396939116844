<template>
  <div>
    <InputSelect
      class="force-w-100 activite__user"
      v-model="activites"
      :items="allActivities"
      :multiple="true"
      item_value="id"
      @change="activiteChange"
      item_text="NomActivite"
      :label="!noLabel ? 'Activités proposées par mon club *' : ''"
      placeholder="Sélectionnez une ou plusieurs activités"
    ></InputSelect>
    <v-row
      :class="!noLabel ? 'justify-content-end' : ''"
      v-if="mounted && activites.length > 0"
    >
      <v-col cols="12" md="9">
        <v-card outlined>
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in activites"
                :key="index"
                @click="
                  () => {
                    !disabled ? TogglePrimaryActivity(item.id) : false;
                  }
                "
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.NomActivite"
                    :selected="false"
                  >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="!noMainActivites">
                  <v-icon
                    :color="
                      item.EST_ActivitePrincipale ? 'yellow' : 'grey lighten-1'
                    "
                  >
                    mdi-star
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-card-title v-if="!noMainActivites" class="justify-content-end">
            <v-icon size="medium" color="yellow">
              mdi-star
            </v-icon>
            <span class="caption ml-2">Activité principale</span>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputSelect from "@/components/utils/selectWithInput.vue";
import { sleep } from "@/plugins/utils";

export default {
  components: { InputSelect },

  data: () => ({
    mounted: false,
    allActivities: [],
    activites: [],
  }),

  async created() {
    let allActi = await this.getListTypeActivite();
    this.allActivities = allActi.map((act) => ({
      ...act,
      EST_ActivitePrincipale: false,
    }));
    this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
  },

  methods: {
    ...mapActions("basics", ["getListTypeActivite"]),

    setData() {
      if (this.currentActivites) {
        this.activites = this.currentActivites.map((act) => ({
          id: act.ID_Activite,
          NomActivite: act.LIST_ActiviteType.NomActivite,
          Ordre: act.LIST_ActiviteType.Ordre,
          EST_ActivitePrincipale: act.EST_ActivitePrincipale,
        }));
        this.activites = this.filterActivites();
      }
    },

    filterActivites() {
      let act = this.activites.slice(0).sort((a, b) => {
        return b.EST_ActivitePrincipale - a.EST_ActivitePrincipale;
      });

      act.forEach((act, index) => {
        act.Ordre = index + 1;
      });

      return act;
    },

    TogglePrimaryActivity(itemId) {
      this.activites.forEach((obj) => {
        obj.EST_ActivitePrincipale = obj.id == itemId ? true : false;
        if (obj.id == itemId) obj.EST_ActivitePrincipale = true;
      });
      this.activiteChange(this.activites);
    },

    async activiteChange() {
      await sleep(10);
      this.activites = this.filterActivites();
      this.$emit("onChange", this.activites);
    },
  },

  props: {
    currentActivites: {
      type: [Array, Object],
      default: () => [],
    },

    noMainActivites: {
      type: Boolean,
      default: false,
    },

    noLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.activite__user .v-select__slot {
  overflow: hidden;
}
</style>
