<template>
  <div class="w-100">
    <v-row v-if="!account">
      <v-col class="p-5"> Veuillez d'abord créer votre compte </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <div class="form-user-details__container mt-6">
          <v-row>
            <v-col cols="3">Nom du compte KYC</v-col>
            <v-col class="d-flex align-center coordonnes_result" cols="3">{{
              account
            }}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col cols="12" md="12" class="status_list">
        <v-simple-table v-if="resultStatus">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Type du document</th>
                <th class="text-left">Statuts du document</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(stat, index) in status" :key="index">
                <td>{{ stat.name }}</td>
                <td>{{ stat.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";

export default {
  data: () => ({
    documentType: null,
    allDocument: [],
    allDocumentType: [
      { name: "Passeport (Union Européenne)", value: 3 },
      { name: "Passeport (hors Union Européenne)", value: 4 },
      { name: "Carte d'identité", value: 0 },
      { name: "Titre de séjour", value: 5 },
      { name: "Permis de conduire", value: 11 },
      { name: "Procès-verbal association (moins d’un an)", value: 17 },
      { name: "Statuts datés et signés", value: 12 },
      { name: "Parution au JOAFE", value: 18 },
    ],
    allStatus: [
      { name: "Document mis en attente", value: 0 },
      { name: "En attente de vérification manuelle", value: 1 },
      { name: "Accepté", value: 2 },
      { name: "Refusé", value: 3 },
      { name: "Refusé : Document illisible", value: 4 },
      { name: "Refusé : Document expiré", value: 5 },
      { name: "Refusé : Mauvais type de document", value: 6 },
      { name: "Refusé : Mauvais nom du titulaire", value: 7 },
      { name: "Refusé : Document en doublon", value: 8 },
    ],
    status: [],
    accountKyc: "",
    resultStatus: false,
  }),

  created() {
    this.setData();
  },

  computed: {
    ...mapGetters("user", ["currentUser"]),
    ...mapGetters("structure", ["currentStructureId"]),
    account() {
      return this.accountKyc;
    },
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUserPaymentAccount",
      "getUserPaymentAccountStructure",
    ]),

    async setData() {
      this.getAllPaymentAccount = await this.getUserPaymentAccountStructure(
        this.currentStructureId
      );
      // const haveAccount = getAllPaymentAccount.find(
      //   (account) => account.ID_Structure == this.currentStructureId
      // );
      this.accountKyc = this.getAllPaymentAccount[0].NomCompte;
      console.log("this.accountKyc", this.accountKyc);
      if (this.accountKyc) {
        await this.checkStatus();
      }
    },

    async checkStatus() {
      this.status = [];
      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/statusDocuments/${this.accountKyc}`,
          {
            method: "get",
            headers: headers,
          }
        );
        const json = await response.json();
        // console.log("response call upload====>", json);
        this.allDocument = json.documents;
        // console.log("this.allDocument ********", this.allDocument);
        // console.log("this.allDocumentType ********", this.allDocumentType);
        var documentstatus = this.allDocument?.map((alldoc) => {
          var document = this.allDocumentType.find(
            (document_type) => document_type.value == alldoc.type
          );
          // console.log("document !!!!", document);
          var status = this.allStatus.find(
            (stat) => stat.value == alldoc.status
          );
          // console.log("status !!!!", status);
          // console.log("response *****", {
          //   name: document.name,
          //   value: status.name,
          // });
          var statusTemp = { name: document.name, value: status.name };
          this.status.push(statusTemp);
          this.resultStatus = true;
        });
        // if (json.id) {
        //   success_notification("Document envoyé");
        // } else {
        //   error_notification(`${json.error.message}`);
        // }
      } catch (error) {
        console.log("erro r:", error);
        error_notification(`${error.message}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
.status_list {
  flex-direction: column;
  align-items: flex-start !important;
}

.coordonnes_result {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
}
</style>
